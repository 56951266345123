<template>
    <div>
        <b-overlay :show="dataLoaded">
            <b-card title="Seznam Vprašanj in odgovorov" class="w-100">
                
                <b-row>
                    <b-col cols="12" sm="6" class="text-center text-sm-left">
                        <b-button variant="primary" @click="$router.push({name: 'faq-add'})" v-if="$hasPermissions($permissions.EditFAQ)">Dodaj Vprašanje in odgovor</b-button>
                    </b-col>
                </b-row>

                <div v-if="items && items.length > 0">
                    <b-alert v-if="!hasBeenDeleted" class="my-1 p-1 text-center" show variant="warning">Z vlečenjem vrstic z vprašanji in odgovori lahko spremenite vrstni red prikaza na spletni strani.</b-alert>
                    <b-alert v-else class="mt-1 p-1 text-center" show variant="warning">Po brisanju je potrebnih nekaj sekund, da je brisanje izvedeno. Če je seoseska po brisanju še vedno viden, ponovno naložite stran.</b-alert>

                    <div class="row mt-3 pb-1 header">
                        <div class="col-3">
                            Avtor
                        </div>
                        <div class="col-3">
                            Vprašanje
                        </div>
                        <div class="col-3">
                            Odgovor
                        </div>
                    </div>

                    <draggable
                        v-model="items"
                        tag="ul"
                        class="list-group list-group-flush cursor-move"
                        id="drag"
                        :disabled="!$hasPermissions($permissions.EditFAQ)"
                    >

                        <b-list-group-item
                            v-for="(item, index) in items"
                            :key="index"
                            tag="li"
                            class="p-0 py-1"
                        >
                            <div class="row d-flex align-items-center">
                                <div class="col-3">
                                    {{item.author}}
                                </div>
                                <div class="col-3 cut-text">
                                    {{item.question}}
                                </div>
                                <div class="col-3 cut-text">
                                    {{item.answer}}
                                </div>
                                <div class="col-3 d-flex justify-content-center" v-if="$hasPermissions($permissions.EditFAQ)">
                                    <!-- <b-button class="mr-2" variant="primary"  :href="'/faq?question_id=' + item.id" target="_blank"><fa icon="newspaper"/></b-button> -->
                                    <b-button variant="warning mr-2" @click="$router.push({name: 'faq-edit', params: {faq_id: item.id}})"><fa icon="edit"/></b-button>
                                    <b-button variant="danger" @click="deleteFaqById(item.id)"><fa icon="trash"/></b-button>
                                </div>
                            </div>
                        </b-list-group-item>
                    </draggable>
                    <div class="d-flex justify-content-end mt-2">
                        <b-button class="mt-2" variant="primary" @click="saveOrder" v-if="$hasPermissions($permissions.EditFAQ)">Shrani vrstni red</b-button>
                    </div>
                </div>

                <div class="mt-2" v-else>
                    <b-alert class="p-1 text-center" show variant="warning">Ni nobenih vprašanj ali odgovorov</b-alert>
                </div>

            </b-card>
        </b-overlay>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import {BListGroupItem, BAlert, BButton, BOverlay, BCard, BRow, BCol} from 'bootstrap-vue'
    export default {
        components: {
            // Table,
            draggable,
            BListGroupItem,
            BAlert,
            BButton,
            BOverlay,
            BCard,
            BRow,
            BCol
        },
        data() {
            return {
                dataLoaded: false,
                items: [],
                hasBeenDeleted: false
            }
        },
        methods:{
            async deleteFaqById(id) {
                this.hasBeenDeleted = false
                const deleteConfirmationResult = await this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati faq.', {
                    size: 'sm',
                    okTitle: 'Da',
                    cancelTitle: 'Ne',
                    hideHeaderClose: false,
                    okVariant: 'danger',
                    cancelVariant: 'outline-secondary',
                    centered: true
                })
                if (!deleteConfirmationResult) return

                try {

                    await this.$http.delete(`/api/management/v1/faq/${id}`)
                    this.hasBeenDeleted = true
                    this.$printSuccess('Faq je bil izbrisan')
                    await this.loadData()

                } catch (err) {
                    this.$printError(err)
                }

            },
            async loadData() {

                try {
                    this.dataLoaded = true
                    const test = await this.$http.get('/api/management/v1/faq')
                    this.items = test.data
                    this.dataLoaded = false
                } catch (err) {
                    this.$printError(err)
                }
            },
            updateSortPlace(faq_object) {
                return this.$http.patch(`/api/management/v1/faq/sort/${faq_object.id}`, faq_object)
            },
            async saveOrder() {
                try {
                    for (const id in this.items) {

                        const index = parseInt(id)

                        if (index + 1 !== this.items[index].sort) {
                            this.items[index].sort = index + 1
                            await this.updateSortPlace(this.items[index])
                        }
                    }
                } catch (err) {
                    this.$printError('Prislo je do napake pri posodabljanju vrstnega reda')
                } finally {
                    this.$printSuccess('Vrstni red uspesno posodobljen')
                }
                
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style>
    #drag .sortable-chosen{
        background: #a1b42070 ;
    }
    .header{
        font-weight: bold;
        border-bottom: 1px solid grey ;
    }
    .cut-text{
        max-width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

</style>